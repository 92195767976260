export default {
  "lang": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Empty'"])}
  },
  "profile": {
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])}
  },
  "designation": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a designation..."])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other "])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Designation"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add Designation"])},
    "amount": {
      "invalid": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a donation amount for this designation"])}
      }
    },
    "invalid": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a designation"])}
    }
  },
  "amount": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Amount"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "recurrence": {
      "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time"])},
      "recur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring"])},
      "freq": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a frequency..."])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
        "bi-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bi-weekly"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
        "invalid": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A frequency must be selected for recurring payments"])}
        }
      }
    },
    "note": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "form": {
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make corrections to continue"])}
    }
  },
  "identify": {
    "logged_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User ", _interpolate(_named("email")), " currently logged in"])},
    "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "fb_auth_error": {
      "weak_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password at least 6 characters in length"])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
      "wrong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong Password"])},
      "user_not_found_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No User Found"])},
      "user_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user registered with this email address, please register or use a different account"])},
      "email_in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email address is already in use by another account."])},
      "email_in_use_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is already in use by another account. Is this yours? Click OK to return to the Login screen to login or cancel to continue as a guest. If you’d like to create an account with a different email address, close this box to proceed."])}
    },
    "login": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity"])},
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is invalid"])}
      },
      "password": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password is invalid"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
        "reset": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address for your account, and we will send you an email with a link to reset your password"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have emailed password reset instructions to the email address provided. Please check your email account to proceed."])},
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately we were unable to send password reset instructions to the email address you provided. Please check the email and try again."])},
          "failure_notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not have an account associated with the email address you have provided. Please verify it's correct and try again, or create a new account."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
          "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
        }
      },
      "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])}
    },
    "register": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "fname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*First Name"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for first name"])},
        "lengthValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name must be at least 2 characters"])},
        "spacesValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first name should not contain any space."])}
      },
      "lname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Last Name"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for last name"])},
        "lengthValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name must be at least 2 characters"])},
        "spacesValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last name should not contain any space."])}
      },
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Email Address"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for email address"])},
        "emailAlreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Already Used"])},
        "emailAlreadyUsedHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address is already in use."])},
        "emailAlreadyMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this is your email, you may have already created an account. Please return to the Login Page to enter your user credentials by clicking the"])},
        "emailAlreadyMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't remember your password you can reset it by clicking the"])},
        "buttonBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["button below:"])},
        "loginPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Login Page"])}
      },
      "pass": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Password (6 character minimum)"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password at least 6 characters in length"])},
        "passwordValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must include a capital letter, a lower case letter, a digit, and a special character."])}
      },
      "pass_confirm": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Confirm Password"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please re-enter the same password as above"])}
      },
      "pass_confirm_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password and password confirmation do not match"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
    },
    "identified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue as a guest"])}
  },
  "pay": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giving Method"])},
    "new_pay_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a new giving method"])},
    "method": {
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
      "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking Account"])}
    },
    "fname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for first name"])},
      "invalid_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a first name, at least 2 characters long"])}
    },
    "lname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for last name"])},
      "invalid_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a last name, at least 2 characters long"])}
    },
    "zip": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-digit zip code is required"])}
    },
    "acc_email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder email is required"])}
    },
    "cc": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card number is required and must be 15 or 16 characters"])}
      },
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder email is required"])}
      },
      "exp": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp. Date"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card expiration date is required"])},
        "invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid MM/YY expiration date."])}
      },
      "cvc": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC Code"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-4 digit security code required"])}
      }
    },
    "ach": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number is required"])}
      },
      "acc_num_2": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Account Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please re-enter the account number to confirm"])}
      },
      "rout_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routing Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9-digit routing number is required"])}
      }
    },
    "save_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save this giving method for future use"])},
    "contrib_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'd like to contribute"])},
    "contrib_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to help cover the administrative costs of this transaction"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give"])},
    "amt_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For card, bank account & recurring donations click 'Next' below"])},
    "t&c": {
      "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
      "verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the 'Give' button you are agreeing to the following"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the 'Give' button, you authorize The Giving Solution to debit the bank account specified above for any amount owed for charges arising from your use of The Giving Solution LLC's services and/or purchase of products and/or services from The Giving Solution, pursuant to The Giving Solution's website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to The Giving Solution with 30 (thirty) days notice.  If you use The Giving Solution's services or purchase additional products periodically pursuant to The Giving Solution's terms, you authorize The Giving Solution to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained."])}
    },
    "donation_err": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Unsuccessful"])},
      "msg_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your donation was not received successfully."])},
      "msg_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please correct any mistakes and/or try again later."])}
    }
  },
  "receipt": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Received"])},
    "thanks_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your donation of"])},
    "delete_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this Donation Method?"])},
    "delete_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this Recurring Donation?"])},
    "delete_recurring_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Recurring"])},
    "thanks_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "thanks_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirmation ID for your donation is"])},
    "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address below if you would like to receive this donation receipt as an email."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "recursiveMessageWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected the weekly recursive plan!"])},
    "recursiveMessageByWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected the bi weekly recursive plan!"])},
    "recursiveMessageMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected the monthly recursive plan!"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*includes transaction contribution)"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
      "success": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Sent!"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This receipt was successfully sent to <em>", _interpolate(_named("email")), "</em>"])}
      },
      "failure": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Not Sent"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your donation completed successfully, however we were unable to email you this receipt"])}
      }
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation breakdown"])}
  },
  "user_profile": {
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "invalid": {
      "invalid_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for Address"])},
      "invalid_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for City"])},
      "invalid_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for State"])},
      "invalid_zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Please enter a value for Zip Code"])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Email"])},
      "invalid_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for Phone"])},
      "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for Password"])},
      "invalid_confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Confirm Password must be same as Password"])}
    },
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
    "updated_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile Successfully"])},
    "incorrect_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect password"])},
    "password_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully updated!"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "edit_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Donation"])},
    "new_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Donation Method"])},
    "add_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Recurring"])},
    "transaction_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])}
  },
  "account": {
    "recurring_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring Donations"])},
    "no_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Recurring Donations Available"])},
    "no_donation_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Donation Methods Available"])},
    "donation_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Methods"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "donation_methods_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Method Deleted Successfully"])},
    "delete_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Donation"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "user_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Account"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "transaction_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Transaction History"])},
    "no_transaction_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Transaction History Available"])},
    "account_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account added successfully!"])},
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
  },
  "constants": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency"])},
    "designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
  }
}