import axios from 'axios'
import AuthService from '../services/AuthService';

let destinationList = {
  stoplight: {
    url: 'https://stoplight.io/mocks/skspay/sks-payment-api-docs/265544/'
  },
  paya: {
    url: 'https://api.sandbox.payaconnect.com/v2/transactions',
    headers: {
      'Content-Type': 'application/json',
      'user-id': '11e9981a9146d442a60a5105',
      'user-api-key': '11ea9d84a2ce993295c2a2a0',
      'developer-id': '9oIk6n7v'
    }
  },
  gs: {
    url: process.env.VUE_APP_API_URL,
    endpoints: {
      validate: '/users/validate',
      organizations: '/organizations',
      users: '/users',
      transactions: '/transactions',
      accounts: '/accounts',
      receipt: '/receipt',
      'payment-cc': '/SubmitCCPayment',
      'payment-ach': '/SubmitACHPayment',
      'payment-token': '/SubmitTokenPayment',
    },
    headers: {
      'Content-Type': 'application/json',
      merchant: process.env['VUE_APP_ORG_KEY']
    }
  }
};

//-- set the environment --//
let destination = destinationList['gs'];

// console.info("apiService :: destination =", destination);

const apiClient = axios.create({
  baseURL: destination.url,
  // withCredentials: false,
  headers: destination.headers
});


export default {
  
  proxyCall: function(action, url, data) {
    console.info("proxyCall() - ", action, url, data);
    const promise = new Promise( (resolve, reject) => {
      AuthService.authenticate()
        .then(() => {
          console.info("apiClient.defaults.headers:", apiClient.defaults.headers);
          apiClient[action](url, data)
            .then( function(response) {
              resolve(response);
            })
            .catch( function(error) {
              reject(error);
            })
          ;
        })
      ;
    });

    return promise;
  },

  authenticatedCall: async function(action, url, data) {
    const accessToken = await AuthService.getAccessToken();
    if(accessToken){
      const authApiClient = axios.create({
        baseURL: destination.url,
        headers: {...destination.headers, Authorization: `Bearer ${accessToken}`}
      });

      const res = await authApiClient[action](url, data);
      return res
    } else {
      return {
        data: null,
        error: "Unauthorized"
      }
    }
  },

  validate(data) {
    return apiClient.post( destination.endpoints['validate'], data );
  },

  post(endpoint, data, ut) {
    if(
      endpoint === 'users'
      /* endpoint === 'payment-cc' || endpoint === 'payment-ach' ||
      endpoint === 'payment-token' || endpoint === 'transactions' ||
      endpoint === 'receipt' */
    ) {
      return apiClient.post( destination.endpoints[endpoint], data );
    } else {
      return this.proxyCall( 'post', destination.endpoints[endpoint], data );
    }
  },

  patch(endpoint, id, data) {
    return this.proxyCall( 'patch', destination.endpoints[endpoint] + "/" + id, data );
  },

  get(endpoint, data) {
    let dataString = JSON.stringify(data);
    let query = encodeURIComponent(dataString);
    let fullUrl = destination.endpoints[endpoint] + "?filter=" + query;

    if(endpoint === 'organizations') {
      return apiClient.get(fullUrl);
    } else {
      return this.proxyCall( 'get', fullUrl );
    }
  },

  updateToken(token) {
    console.info("updateToken()", token);
    apiClient.defaults.headers.common['Authorization'] = "Bearer " + token;
  }
  // Jake code
  // setAuthHeader(token) {
  //   console.info("setAuthHeader()", token);
  //   apiClient.defaults.headers.common['Authorization'] = "Bearer " + token;
  // }
 // Jake code
};

