<template>
  <!-- <div id="nav"> -->
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- <router-link to="/payments">Payments</router-link> | -->
    <!-- <router-link to="/about">About</router-link> -->
    <!-- <router-link to="/identify">Identify</router-link>
    <router-link to="/amount">Amount</router-link>
    <router-link to="/pay-new-card">Pay - New Card</router-link> -->
    <!-- &bull; -->
  <!-- </div> -->

  <router-view />

  <div id="loader-wrapper">
    <div class="loader is-loading"></div>
  </div>

  <!-- <div id="lang" class="has-text-centered">
    <a v-on:click="setLocale('en')">
      {{ $t('lang.english') }}
    </a>
    <span> | </span>
    <a v-on:click="setLocale('es')">
      {{ $t('lang.spanish') }}
    </a>
  </div> -->
  <footer class="column is-three-fifths is-offset-one-fifth">
    <a id="copyright" href="https://givingsolution.net">
      <span>&reg;</span> Giving Solution 2023
    </a>
  </footer>

</template>

<script>
  // import { getCurrentInstance } from 'vue';
  import { version, watch, computed } from 'vue';
  // import { useRouter, useRoute } from 'vue-router';
  import ApiService from '@/services/ApiService';

  document.addEventListener('securitypolicyviolation', console.error.bind(console));

  /* function storageAvailable() {
    let storage;
    try {
      storage = window['localStorage'];
      const x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  } */

  // var postMsg = {type: 'gs', action: 'set', data: {"login": "user"}}; // this is just example
  // parent.postMessage(postMsg, "https://127.0.0.1:5150/iframe-demo.html");


  export default {
    data() {
      return {
        org: {},
        APP_PROPS: {
          ENV: process.env.NODE_ENV,
          MODE: process.env.VUE_APP_MODE,
          VUE_VERSION: version,
          APP_VERSION: process.env.VUE_APP_APP_VERSION,
        }
      }
    },
    provide() {
      return {
        //-- application level config --//
        APP_PROPS: this.APP_PROPS,
        org: computed(() => this.org),
      }
    },
    created() {
      console.info("App - created()", this.APP_PROPS);

      // const router = useRouter();
      // const route = useRoute();
      
      // console.info("router:", router, "route:", route);

      // // this.orgKey = route.params.org;
      // console.info("currentRoute:", this.$router.currentRoute.value);
    },
    //-- determine the org name from the path org key --//
    watch: {
      '$route.params.org': {
        immediate: true,
        deep: true,
        handler(newOrg, oldOrg) {
          let cssFailed = false;
          let router = this.$router;

          if(newOrg === undefined) {
            console.log("route undefined");
            return;
          }

          console.log("$route.params.org:", newOrg);

          const orgKey = newOrg;

          // console.info(`orgKey is '${orgKey}'`);
          // alert("org:", org);
          if(orgKey == "demo") {
            import('/public/css/default.css');
          } else {
            import(`/public/css/${orgKey}.css`)
              .catch(function() {
                console.error("CSS import failed :(");
                cssFailed = true;
                // router.push({name: '404'});
                // return false;
                // import('/public/css/default.css')
              })
            ;
          }

          try {

            let desQuery = {
              "where": {
                "key": orgKey
              }
            };

            ApiService.get('organizations', desQuery)
              .then((response) => {
                console.log("orgs GET full response", response);
                console.log("orgs GET successful!", response.data);

                if(response.data.length === 0) throw new Error('No organization with that key found');

                const org = JSON.stringify(response.data[0]);
                // const available = storageAvailable();

                // if(available === true) {
                  localStorage.org = org;
                // } else {
                  // var postMsg = {type: 'gs', action: 'set', data: {'org': org}}; // this is just example
                  // parent.postMessage(postMsg, "https://127.0.0.1:5150/iframe-demo.html");
                // }

                this.org = response.data[0];
              })
              .catch((error) => {
                console.error("'organizations' GET unsuccessful.", error);
                if(cssFailed === true) {
                  router.push({name: '404'});
                }
              })
            ;

          } catch(err) {
            console.error("error detecting org.", err);
            router.push({name: '404'});
            // return false;
          }

        }
      },

    },
    mounted() {
      console.info("App - mounted()");
      if(localStorage.getItem('locale')) {
        this.$i18n.locale = localStorage.getItem('locale');
      }

      console.info("App.vue - route = ", this.$route.params);

    },
    methods: {
      setLocale(locale) {
        this.$i18n.locale = locale;
        localStorage.setItem('locale', locale);
      }
    }
  }
</script>

<style>
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  @media (max-width: 768px) {
    .column.box > .m-3 {
      margin: 0 !important;
    }
  }
  @media (max-width: 1120px) {
    #app #main-columns .column.is-offset-one-fifth,
    #app #main-columns .column.is-offset-one-fifth-tablet {
      margin-left: 0;
    }
    #app #main-columns .column.is-three-fifths,
    #app #main-columns .column.is-three-fifths-tablet
    {
      flex: 1;
      width: 100%;
    }
  }
  /* #nav {
    display: none;
  } */

  .columns {
    clear: both;
  }

  #app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    overflow: auto;
  }

  /*
  #nav {
    padding: 30px;
    text-align: center;
    background-color: #0da49b;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a {
    padding: 0 7px;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
  */

  #loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #dedede;
    /* opacity: 0; */
    /* z-index: -1; */
    transition: opacity .3s;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }

  #loader-wrapper.is-active {
    opacity: 0.9;
    z-index: 999;
    display: flex;
  }

  #loader-wrapper .loader {
    height: 80px;
    width: 80px;
    border-width: 3px;
    border-color: #dedede #ffffff;
  }

  #main-columns {
    margin-left: 0;
    margin-right: 0;
  }

  #main-columns > .box.column {
    margin-bottom: 7px;
  }

  #header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px 25px 20px;
  }

  #header #nav-left {
    position: absolute;
    left: 10%;
    font-size: 15px;
  }

  #header #nav-right {
    position: absolute;
    right: 10%;
    font-size: 15px;
  }

  /* #lang {
    margin: 1.5em 0;
  }

  #lang a {
    margin: 0 0.5em;
  } */

  footer.column {
    display: none;
    height: 75px;
    padding-top: 0;
    background-image: url(https://s3.us-east-2.amazonaws.com/gs.net-public/gs-logo_gray-trans-top.png);
    /* background-size: 6em; */
    background-position: right 1em top;
    background-repeat: no-repeat;
  }

  footer.column:hover {
    background-image: url(https://s3.us-east-2.amazonaws.com/gs.net-public/gs-logo_color-trans-top.png);
  }

  footer #copyright {
    font-size: 14px;
    color: #777;
  }

  footer a#copyright:hover {
    color: #8CC53F;
  }

  footer:hover a#copyright span {
    color: #8CC53F;
  }

</style>
