export default {
  "lang": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Empty'"])}
  },
  "profile": {
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Sesión"])},
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Perfil"])}
  },
  "designation": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione designación..."])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro "])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quite Designación"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Añada Designación"])},
    "amount": {
      "invalid": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de escoger una cantidad para donar por esta designación"])}
      }
    },
    "invalid": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de escoger una designación"])}
    }
  },
  "amount": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoja Cantidad"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "recurrence": {
      "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una Vez"])},
      "recur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurrente"])},
      "freq": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione la frecuencia..."])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diario"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanal"])},
        "bi-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada Dos Semanas"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensual"])},
        "invalid": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar la frecuencia para los pagos recurrentes"])}
        }
      }
    },
    "note": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "form": {
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de hacer correcciones para continuar"])}
    }
  },
  "identify": {
    "logged_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuario% ", _interpolate(_named("email")), " actualmente en uso"])},
    "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "fb_auth_error": {
      "weak_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de entrar una contraseña con al menos 6 caracteres"])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de entrar correo electrónico válido"])},
      "wrong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña incorrecta"])},
      "user_not_found_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró usuario"])},
      "user_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No usuario registrado con este correo, favor de registrarse o usar una cuenta diferente"])},
      "email_in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo está en uso por otra cuenta."])},
      "email_in_use_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico ya está siendo utilizado por otra cuenta. ¿Es tuyo? Haz clic en Aceptar para volver a la pantalla de inicio de sesión e ingresar, o cancela para continuar como invitado. Si deseas crear una cuenta con una dirección de correo electrónico diferente, cierra esta ventana para continuar."])}
    },
    "login": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad"])},
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de Correo Electrónico"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico no es válido"])}
      },
      "password": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este contraseña no es válido"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Se le olvidó la contraseña?"])},
        "reset": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinicie la contraseña"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre correo electrónico de su cuenta, y le enviaremos un correo con un enlace para reiniciar la contraseña."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le enviamos instrucciones a su correo electrónico para reiniciar la contraseña. Vaya a su correo electrónico para proseguir."])},
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafortunadamente no podemos enviar instrucciones para reiniciar la contraseña al correo electrónico que usted ha provisto. Favor de ver su buzón de correo."])},
          "failure_notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tenemos una cuenta asociada con el correo electrónico que nos ha provisto. Favor de verificar que esta correcto y trate de nuevo, o crea una cuenta."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de entrar una dirección electrónica válida"])},
          "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
        }
      },
      "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectase"])}
    },
    "register": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Cuenta"])},
      "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tiene una cuenta?"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Cuenta"])},
      "fname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Primer Nombre"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de ingresar su primer nombre"])},
        "lengthValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El primer nombre debe tener al menos 2 caracteres."])},
        "spacesIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre no debe contener espacios."])}
      },
      "lname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Apellido"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de ingresar su apellido"])},
        "lengthValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El apellido debe tener al menos 2 caracteres."])},
        "spacesIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El apellido no debe contener espacios."])}
      },
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Dirección Correo Electrónico"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de ingresar un correo electrónico"])},
        "emailAlreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico ya utilizado"])},
        "emailAlreadyUsedHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta dirección de correo electrónico ya está en uso."])},
        "emailAlreadyMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si este es tu correo electrónico, es posible que ya hayas creado una cuenta. Por favor, regresa a la página de inicio de sesión para ingresar tus credenciales de usuario haciendo clic en el enlace."])},
        "emailAlreadyMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no recuerdas tu contraseña, puedes restablecerla haciendo clic en el enlace."])},
        "buttonBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["botón de abajo:"])},
        "loginPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de inicio"])}
      },
      "pass": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Contraseña (mínimo de 6 caracteres)"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor entrar contraseña (mínimo 6 caracteres)"])},
        "passwordValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe incluir al menos una letra mayúscula, una letra minúscula, un dígito y un carácter especial."])}
      },
      "pass_confirm": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Confirme Contraseña"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de repetir la contraseña"])}
      },
      "pass_confirm_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña y su confirmación no combinan"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])}
    },
    "identified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificado"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continúe como invitado"])}
  },
  "pay": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de Dar"])},
    "new_pay_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use otro método de dar"])},
    "method": {
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de Crédito"])},
      "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de Cheques"])}
    },
    "fname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer Nombre"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de ingresar si primer nombre"])},
      "invalid_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un nombre con al menos 2 caracteres"])}
    },
    "lname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de ingresar su apellido"])},
      "invalid_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un apellido con al menos 2 caracteres."])}
    },
    "zip": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona Postal"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona Postal de 5 dígitos es requerida"])}
    },
    "acc_email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico es requerido"])}
    },
    "cc": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de la Tarjeta"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de tarjeta de crédito es obligatorio y debe tener 15 o 16 caracteres."])}
      },
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere el correo electrónico del titular de la cuenta"])}
      },
      "exp": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Expiración"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Expiración"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de expiración es requerida"])},
        "invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, proporcione una fecha de vencimiento válida en formato MM/AA"])}
      },
      "cvc": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código CVC"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere código de seguridad de 3 a 4 dígitos."])}
      }
    },
    "ach": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Cuenta"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta es requerido"])}
      },
      "acc_num_2": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme Número de Cuenta"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de repetir número de cuenta para confirmar"])}
      },
      "rout_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Ruta Bancaria"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de enrutamiento es requerido"])}
      }
    },
    "save_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarde el método de dar para usos futuros"])},
    "contrib_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me gustaría contribuir"])},
    "contrib_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para ayudar con los gastos de esta transacción"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
    "amt_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si desea usar tarjeta, cuenta bancaria o hacer una donación recurrente click ‘Proximo’ abajo"])},
    "t&c": {
      "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])},
      "verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pulsar el botón de 'Dar' usted está aprobando lo siguiente"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pulsar el botón de 'Dar', usted autoriza The Giving Solution a debitar la cuenta de banco especificada arriba por cualquier cantidad adeudada por los cargos derivados del uso de los servicio es de The Giving Solution LLC y/o la compra de productos y/o servicios de The Giving Solution, de acuerdo a la página WEB y los términos de The Giving Solution, hasta que esta autorización sea revocada. Usted puede enmendar o cancelar esta autorización en cualquier momento proveyendo un aviso a The Giving Solution con treinta (30) días de anticipación. Si usted usa los servicios de The Giving Solution o compra productos adicionales periódicamente de acuerdo con los términos de The Giving Solution, usted autoriza a The Giving Solution a cargar su cuenta de banco periódicamente. Los pagos que caigan fuera de los cargos regulares autorizados arriba solamente serán debitados después de que sea obtenida su autorización."])}
    },
    "donation_err": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donación No Exitosa"])},
      "msg_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su donación no fue recibida exitosamente"])},
      "msg_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de corregir cualquier error y/o trate más tarde"])}
    }
  },
  "receipt": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regalo Recibido"])},
    "thanks_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por su donativo de"])},
    "delete_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás seguro/a de que quieres eliminar este Método de Donación?"])},
    "delete_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás seguro de que deseas eliminar esta Donación Periódica?"])},
    "delete_recurring_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Periódica"])},
    "thanks_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para"])},
    "thanks_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su número de confirmación es"])},
    "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor de ingresar su correo electrónico si desea un recibo."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
    "recursiveMessageWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Has seleccionado el plan recursivo semanal!"])},
    "recursiveMessageByWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Has seleccionado el plan recursivo quincenal!"])},
    "recursiveMessageMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Has seleccionado el plan recursivo mensual!"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*incluye aporte de transacción)"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Correo Electrónico"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es un campo requerido"])},
      "success": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Correo electrónico enviado!"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este recibo fue enviado exitosamente a <em>", _interpolate(_named("email")), "</em>"])}
      },
      "failure": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico no enviado"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su donación fue completada con éxito, sin embargo, no pudimos enviarle este recibo"])}
      }
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evíe"])},
    "breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de su donación"])}
  },
  "user_profile": {
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección 1"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección 2"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
    "invalid": {
      "invalid_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un valor para la dirección."])},
      "invalid_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un valor para la ciudad."])},
      "invalid_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un valor para el estado."])},
      "invalid_zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un valor para el código postal."])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un correo electrónico válido."])},
      "invalid_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un valor para el teléfono."])},
      "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un valor para la contraseña."])},
      "invalid_confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La confirmación de contraseña debe ser igual a la contraseña."])}
    },
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar contraseña"])},
    "password_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actualizada exitosamente!"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Contraseña"])},
    "incorrect_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña incorrecta"])},
    "updated_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil editado exitosamente"])},
    "edit_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar donación"])},
    "new_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo método de donación"])},
    "add_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Nueva Donación Recurrente"])},
    "transaction_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de transacciones"])}
  },
  "account": {
    "recurring_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donaciones recurrentes"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Completo"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "no_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay donaciones recurrentes disponibles"])},
    "no_donation_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay métodos de donación disponibles."])},
    "donation_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de donación"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "donation_methods_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de Donación Eliminado Exitosamente"])},
    "delete_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Donación"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
    "user_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de usuario"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "transaction_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver historial de transacciones"])},
    "no_transaction_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay historial de transacciones disponible."])},
    "account_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta añadida exitosamente!"])},
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
    "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Nueva Cuenta"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Nueva Donación Recurrente"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])}
  },
  "constants": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frecuencia"])},
    "designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designación"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])}
  }
}