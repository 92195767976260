(function() {

  // Storing a reference to the original localStorage object
  let originalLocalStorage = undefined;
  let fauxLocalStorage = {};

  try {
    originalLocalStorage = window.localStorage;
  } catch(e) {
    console.warn("localStorage unavailable; will fallback to alternative custom implementation");
  }

  const localStorageAvailable = (() => {
    let storage;
    try {
      storage = window['localStorage'];
      const x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  })();

  // Create a custom implementation
  const customLocalStorage = {
    setItem: function(key, value) {
      console.log('Custom setItem called'); // Just an example
      if(localStorageAvailable === true) {
        originalLocalStorage.setItem(key, value); // Use the original functionality
      } else {
        console.log("setItem: No localStorage");
        fauxLocalStorage[key] = value;
      }
    },
    getItem: function(key) {
      console.log('Custom getItem called'); // Just an example
      if(localStorageAvailable === true) {
        // return originalLocalStorage.getItem(key); // Use the original functionality
        return originalLocalStorage.getItem.call(originalLocalStorage, key);
      } else {
        console.log("getItem: No localStorage");
        return fauxLocalStorage[key];
      }
    },
    removeItem: function(key) {
      console.log('Custom removeItem called'); // Just an example
      if(localStorageAvailable === true) {
        return originalLocalStorage.removeItem(key); // Use the original functionality
      } else {
        console.log("removeItem: No localStorage");
        delete fauxLocalStorage[key];
      }
    },
    clear: function() {
      console.log('Custom clear called'); // Just an example
      if(localStorageAvailable === true) {
        return originalLocalStorage.clear(); // Use the original functionality
      } else {
        console.log("clear: No localStorage");
        fauxLocalStorage = {};
      }
    },
  };

  const customLocalStorageHandler = {
    get: function(target, property) {
      // Handle method calls
      if (typeof target[property] === "function") {
        return target[property].bind(target);
      }

      // Handle direct property access
      if(localStorageAvailable === true) {
        return originalLocalStorage[property];
      } else {
        return fauxLocalStorage[property];
      }
    },
    set: function(target, property, value) {
      if(localStorageAvailable === true) {
        originalLocalStorage[property] = value;
      } else {
        fauxLocalStorage[property] = value;
      }
      return true;
    }
  };

  const localStorageProxy = new Proxy(customLocalStorage, customLocalStorageHandler);

  // Override the localStorage API
  Object.defineProperty(window, 'localStorage', {
    value: localStorageProxy,
    writable: false,
    configurable: true,
    enumerable: true
  });

})();
