import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

// import '/public/css/mystyles.css';
// import 'bulma/css/bulma.css';

import VueCreditCardValidation from 'vue-credit-card-validation';

import ApiService from '@/services/ApiService';

// Import the functions you need from the SDKs you need
import { getAuth, onAuthStateChanged, connectAuthEmulator, onIdTokenChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import i18n from './i18n'
import AuthService from "./services/AuthService";

import "./utils/localStorage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// TODO: Make this Firebase config environment-aware

//-- local dev --//  -- currently using Firebase's emulator locally: https://firebase.google.com/docs/emulator-suite/
/* const firebaseConfig = {
  apiKey: "AIzaSyDJm0dRTetEzCjfkQi4UIX5MechmqmF9wc",
  authDomain: "sksvueweb.firebaseapp.com",
  projectId: "sksvueweb",
  storageBucket: "sksvueweb.appspot.com",
  messagingSenderId: "256942548080",
  appId: "1:256942548080:web:21fc3c97401531415440b5",
  measurementId: "G-N7GH09SKT7"
}; */

//-- QA - V.net --//
const firebaseConfig = {
  apiKey: process.env['VUE_APP_FB_apiKey'],
  authDomain: process.env['VUE_APP_FB_authDomain'],
  projectId: process.env['VUE_APP_FB_projectId'],
  storageBucket: process.env['VUE_APP_FB_storageBucket'],
  messagingSenderId: process.env['VUE_APP_FB_messagingSenderId'],
  appId: process.env['VUE_APP_FB_appId'],
  measurementId: process.env['VUE_APP_FB_measurementId']
};

// TODO: Create a UAT/Demo Firebase account, and update values here
//-- UAT/Demo --//
/* const firebaseConfig = {
  apiKey: "",
  authDomain: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",
  measurementId: ""
}; */

// TODO: Create a Production Firebase account, and update values here
//-- Production --//
/* const firebaseConfig = {
  apiKey: "",
  authDomain: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",
  measurementId: ""
}; */

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();

let myApp;
myApp = createApp(App);  // , {foo: "bar", fb_user: fb_user}
myApp.use(router);
myApp.use(i18n);
myApp.use(VueCreditCardValidation);
myApp.config.unwrapInjectedRef = true;

myApp.mount("#app");

// alert(window.location);
console.info("router", router.currentRoute);

onAuthStateChanged(auth, (fb_user) => {
  console.info("onAuthStateChanged - ", auth);

  if (fb_user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    console.log("Main - fb_user:", fb_user);
    // this.fb_user = fb_user;
    // this.logged_in = true;
    //-- stringify() doesn't capture everything we're interested in, so we'll need to create our own object to store --//
    const fbUser = fb_user.toJSON();
    fbUser['accessToken'] = fb_user['accessToken'];
    localStorage.setItem("fb_user", JSON.stringify(fbUser));
  } else {
    // No user is signed in.
    console.log("no firebase fb_user");
    localStorage.removeItem("fb_user");
    AuthService.clearJwt();
  }
});

/* auth.onIdTokenChanged(user => {
  console.info("onIdTokenChanged()", user);
  if (user) {
    // User is signed in or token was refreshed.
  }
}); */

//-- comment out to this 2-line override to use environment values --//
// const auth = getAuth(app);
// connectAuthEmulator(auth, "http://localhost:9099");

// const getCurrentAppVersion = async () => {
//   const result = await AppUpdate.getAppUpdateInfo();
//   return result.currentVersion;
// };

// console.log(getCurrentAppVersion());



// console.info("myApp.config", myApp.config);