import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";

const routes = [
  /* {
    path: '/',
    redirect: '/demo/amount'
  },
  {
    path: '/demo',
    redirect: '/demo/amount',
  }, */
  {
    path: "/:org",
    redirect: (to) => {
      return `/${to.params.org}/amount`;
    },
  },
  /* {
    path: '/:pathMatch(.*)*',
    redirect: (to) => {
      return `${to.path}/amount`
    }
  }, */
  /* {
    path: '/:path/amount',
    component: 'AmountView'
  }, */
  {
    name: "AmountView",
    path: "/:org/amount",
    component: () => import("../views/Amount.vue"),
  },
  {
    name: "IdentifyView",
    path: "/:org/identify",
    component: () => import("../views/Identify.vue"),
  },
  {
    name: "GiveView",
    path: "/:org/give",
    component: () => import("../views/Give.vue"),
  },
  /*{ 
    name: "TesttxtView",
    path: "/:org/test.txt",
    //component: () => import("../views/testtxt.vue"),
    component: () => import("../views/test.txt"),
  },*/
  {
    name: "ReceiptView",
    path: "/:org/receipt",
    component: () => import("../views/Receipt.vue"),
    // docs indicate this should work here, but it doesn't
    /* beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave()");
      // Navigate back two pages in the history
      window.history.go(-3);
      // Cancel the navigation
      next(false);
    } */
  },
  {
    name: "Maintenance",
    path: "/:org/maintenance",
    component: () => import("../views/Maintenance.vue"),
  },
  {
    name: "AccountView",
    path: "/:org/account",
    component: () => import("../views/Account.vue"),
  },
  {
    name: "EditProfile",
    path: "/:org/account/edit-profile",
    component: () => import("../views/Account/EditProfile.vue"),
  },
  {
    name: "AddDonation",
    path: "/:org/account/add-donation",
    component: () => import("../views/Account/AddDonation.vue"),
  },
  {
    name: "AddRecurring",
    path: "/:org/account/add-recurring",
    component: () => import("../views/Account/AddRecurring.vue"),
  },
  {
    name: "EditDonation",
    path: "/:org/account/edit-donation",
    component: () => import("../views/Account/EditDonation.vue"),
    props: route => ({ org: route.params.org, editIndex: route.query.editIndex }),
  },
  {
    name: "EditRecurring",
    path: "/:org/account/edit-recurring",
    component: () => import("../views/Account/EditRecurring.vue"),
  },
  {
    name: "TransactionHistory",
    path: "/:org/account/transaction-history",
    component: () => import("../views/Account/TransactionHistory.vue"),
  },
  // {
  //   name: "404",
  //   path: "/:org/404",

  //   component: () => import("../views/404.vue")
  // },
  {
    name: "Org404",
    path: "/:org/:pathMatch(.*)*",
    component: () => import("../views/404.vue"),
  },
  {
    name: "404",
    path: "/:pathMatch(.*)*",
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
